/****************************************************** */
/******************  Main Header style **************** */
/****************************************************** */
.main-header {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 9999;
    background: #FFFFFF;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.fixed-header {
    box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, .10);
}

.header-inner {
    position: relative;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

/** Header Main Menu */

.main-menu .navbar-collapse {
    padding: 0px;
}

.main-menu .navigation li {
    float: left;
    padding: 35px 25px;
}

.main-menu .navigation li.dropdown > a {
    position: relative;
}

.main-menu .navigation li.dropdown > a:after {
    content: '+';
    position: absolute;
    top: 0;
    right: -14px;
}

.main-menu .navigation li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 0;
    width: 50px;
    height: 39px;
    color: white;
    display: none;
    cursor: pointer;
    line-height: 43px;
    text-align: center;
    border-left: 1px solid #eeeeee2b;
}

.main-menu .navigation li a {
    position: relative;
    display: block;
    color: #68616d;
    font-weight: 700;
    opacity: 1;
    font-size: 17px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.main-menu .navigation li:hover > a,
.main-menu .navigation li > a.current-menu-item {
    color: #1F0039;
}

.main-menu .navigation li ul {
    position: absolute;
    left: 0;
    top: 100%;
    min-width: 200px;
    z-index: 100;
    display: none;
    background: #FFFFFF;
    border-radius: 0 0 5px 5px;
    box-shadow: inset 0 0 0 10px #FFFFFF, 0 5px 15px -5px rgba(0,0,0,.2);
}

.main-menu .navigation li ul li {
    width: 100%;
    padding: 7px 20px;
    border-bottom: 1px solid #0d1b4a;
}

.main-menu .navigation li ul li:last-child {
    border-bottom: none;
}

.main-menu .navigation li ul li a {
    padding: 8px 5px;
    line-height: 24px;
    color: #FFFFFF;
    font-weight: 500;
}

.main-menu .navigation li ul li ul {
    left: 100%;
    top: 0%;
}

.main-menu .navbar-header {
    display: none;
}

.main-menu .navbar-header .navbar-toggle {
    float: right;
    padding: 4px 0;
    cursor: pointer;
    background: transparent;
}

.main-menu .navbar-header .navbar-toggle .icon-bar {
    height: 2px;
    width: 30px;
    display: block;
    margin: 7px 5px;
    background: #1F0039;
}

.menu-btn .theme-btn {
    color: #1F0039;
    font-size: 17px;
    font-weight: 700;
    padding: 15px 23px;
    border-color: #2EB8F7;
    background: transparent;
    box-shadow: 0px 5px 10px #2EB8F733;
}


@media only screen and (max-width: 1199px) {
    .main-menu .navigation li {
        padding: 35px 15px;
    }
    
}


/* Mobile Menu */

@media only screen and (max-width: 991px) {
    .header-inner {
        padding: 20px 0;
    }
    .fixed-header {
        top: 0;
    }
    .menu-btn {
        display: none;
    }
    .main-menu .navigation li:before {
        content: none;
    }
    .main-header .header-upper .container-fluid {
        padding: 0;
    }
    .main-header .logo-outer {
        position: absolute;
        width: 155px;
        top: 25px;
        -webkit-transition: all 500ms ease;
        transition: all 500ms ease;
    }
    .main-menu {
        width: 100%;
    }
    .main-menu .navbar-header {
        display: block;
    }
    .main-menu .navigation {
        overflow: auto;
        max-height: 310px;
        margin: 50px 0 25px;
    }
    .main-menu .navigation li {
        float: none;
        padding: 0 20px;
        border-top: 1px solid #b0b0b02b;
    }
    .main-menu .navigation li:last-child {
        border-bottom: 1px solid #b0b0b02b;
    }
    .main-menu .navigation li.dropdown .dropdown-btn {
        display: block;
    }
    .main-menu .navigation li a {
        font-size: 16px;
        padding: 7px 10px;
        line-height: 22px;
    }
    .main-menu .navigation li a:after {
        display: none;
    }
    .main-menu .navigation li ul {
        position: relative;
        display: none;
        width: 100%;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: transparent;
    }
    .main-menu .navigation li ul:after {
        display: block;
        clear: both;
        content: "";
    }
    .main-menu .navigation li ul li {
        padding: 0 20px;
        border-bottom: none;
    }
    .main-menu .navigation li ul li ul {
        left: auto;
    }
}

